.glider, .glider-contain {
  margin: 0 auto;
  position: relative;
}

.glider, .glider-track {
  transform: translateZ(0);
}

.glider-dot, .glider-next, .glider-prev {
  user-select: none;
  border: 0;
  outline: 0;
  padding: 0;
}

.glider-contain {
  width: 100%;
}

.glider {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  overflow-y: hidden;
}

.glider-track {
  width: 100%;
  z-index: 1;
  margin: 0;
  padding: 0;
  display: flex;
}

.glider.draggable {
  user-select: none;
  cursor: -webkit-grab;
  cursor: grab;
}

.glider.draggable .glider-slide img {
  user-select: none;
  pointer-events: none;
}

.glider.drag {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.glider-slide {
  user-select: none;
  width: 100%;
  min-width: 150px;
  place-content: center;
}

.glider-slide img {
  max-width: 100%;
}

.glider::-webkit-scrollbar {
  opacity: 0;
  height: 0;
}

.glider-next, .glider-prev {
  z-index: 2;
  cursor: pointer;
  color: #666;
  opacity: 1;
  background: none;
  font-size: 40px;
  line-height: 1;
  text-decoration: none;
  transition: opacity .5s cubic-bezier(.17, .67, .83, .67), color .5s cubic-bezier(.17, .67, .83, .67);
  position: absolute;
  top: 30%;
  left: -23px;
}

.glider-next:focus, .glider-next:hover, .glider-prev:focus, .glider-prev:hover {
  color: #ccc;
}

.glider-next {
  left: auto;
  right: -23px;
}

.glider-next.disabled, .glider-prev.disabled {
  opacity: .25;
  color: #666;
  cursor: default;
}

.glider-hide {
  opacity: 0;
}

.glider-dots {
  user-select: none;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  display: flex;
}

.glider-dot {
  cursor: pointer;
  color: #ccc;
  width: 12px;
  height: 12px;
  background: #ccc;
  border-radius: 999px;
  margin: 7px;
  display: block;
}

.glider-dot:focus, .glider-dot:hover {
  background: #ddd;
}

.glider-dot.active {
  background: #a89cc8;
}

@media (max-width: 36em) {
  .glider::-webkit-scrollbar {
    opacity: 1;
    -webkit-appearance: none;
    width: 7px;
    height: 3px;
  }

  .glider::-webkit-scrollbar-thumb {
    opacity: 1;
    background-color: #9c9c9c40;
    border-radius: 99px;
    box-shadow: 0 0 1px #ffffff40;
  }
}

/*# sourceMappingURL=index.1e05a9b1.css.map */
